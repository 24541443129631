import t from '../common/localization';

export default {
  speedLimit: {
    name: t('attributeSpeedLimit'),
    type: 'string',
  },
  'report.ignoreOdometer': {
    name: t('attributeReportIgnoreOdometer'),
    type: 'boolean',
  },
};
